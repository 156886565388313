import React, { useEffect } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  Polyline,
  Marker,
  InfoWindow,
  GroundOverlay
} from "@react-google-maps/api";
import { milestone_percentage_config } from "../../globalConfig/milestones";
import logo from '../../assets/logo.png';
import lockedwaypointLogo from '../../assets/locked-waypoint.png'
import unlockedWaypointLogo from '../../assets/unlocked-waypoint.png'
import openableWaypointLogo from '../../assets/openable-waypoint.png'
import "./style.css"

// import { coordinates, markers, coordinates1 } from "./latlang";
import icon from "../../assets/marker.png";
import userIcon from "../../assets/userMarker.png";
import icon_milestone from "../../assets/milestone.png";
import { useNavigate, useParams,  } from "react-router-dom";
import { getUsersByProductId, getChallengeById, getWaypointsById } from "../../globalService/api.service";
import { Challenge, WayPoint } from "../../globalService/api.model";
// import { coordinates } from "./latlang";
import Modal from "./Modal";


const googleMapsApiKey = "AIzaSyAFgWyTzNPhooiqiZJIr-Judt3Wo14w6GE";
const containerStyle = {
  width: "100%",
  height: "calc(100vh - 60px)",
};

const getWaypointLogo = (is_openable, is_unlocked ) => {

  if(is_openable || is_unlocked) {

    if(is_unlocked) return unlockedWaypointLogo
    else if(is_openable) return openableWaypointLogo;

  } else return lockedwaypointLogo;

}


function MyComponent(props) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: googleMapsApiKey,
  });

  // const [map, setMap] = React.useState(null)

  // const onLoad = React.useCallback(function callback(map) {
  //   const bounds = new window.google.maps.LatLngBounds(center);
  //   map.fitBounds(bounds);
  //   setMap(map)
  // }, [])

  // const onUnmount = React.useCallback(function callback(map) {
  //   setMap(null)
  // }, [])

  const [challenge, setChallenge] = React.useState(null)
  const [google, setGoogle] = React.useState(null);
  const [totalDistance, setTotalDistance] = React.useState(0);
  const [users, setUsers] = React.useState([]);
  const params = useParams();
  const navigate = useNavigate();
  const [polyline, setPolyline] = React.useState(null);
  const [markers, setMarkers] = React.useState([]);
  const [infoWindow, setInfoWindow] = React.useState(null)
  const [text, setText] = React.useState("")
  const [center, setCenter] = React.useState({ lat: 0, lng: 0 })
  const [milestones, setMilestones] = React.useState([]);
  const [waypointsData, setWaypointsData] = React.useState([])
  const [waypoints, setWaypoints] = React.useState([])
  const [waypoint, setWaypoint] = React.useState(null)

  const {userid} = params

  const onMapLoad = async () => {
    let myGoogle = window.google as any;
    setGoogle(myGoogle);

  };

  const [isModalOpen, setIsModalOpen] = React.useState("");

  const handleOpenModal = (wp: WayPoint, position, is_openable: boolean, is_unlocked: boolean) => {
    if(is_openable || is_unlocked) {
      setIsModalOpen(is_unlocked ? "is_unlocked" : "is_openable");
      setWaypoint(wp)
    } else {
      setInfoWindow(<InfoWindow onCloseClick={() => setInfoWindow(null)} position={position}>
      <div>
        <p>
          This waypoint is locked. Your previous waypoints maybe locked or you have not yet reached this point.
        </p>
      </div>
    </InfoWindow>)
    }
    
  };

  const handleCloseModal = () => {
    setIsModalOpen("");
    setWaypoint(null)
  };

  const options = {
    strokeColor: "#c17846",
    strokeOpacity: 1.0,
    strokeWeight: 3,
    fillColor: "#FF0000",
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 30000,
    // paths: coordinates,
    zIndex: 1,
  };
  useEffect(() => {
    // console.log("center", users);
    
    if (challenge) {
      const { pathCoordinates } = challenge;

      const getCenterLat = () => {
        const center = pathCoordinates[Math.floor(pathCoordinates.length / 2)];
        return center.lat;
      };
      const getCenterLng = () => {
        const center = pathCoordinates[Math.floor(pathCoordinates.length / 2)];
        return center.lng;
      };

      setCenter({
        lat: pathCoordinates ? getCenterLat() : 51.49335985927199,
        lng: pathCoordinates ? getCenterLng() : -0.13751916734155545,
      });
    }
  }, [challenge])


  useEffect(() => {
    getUser()
    getChallenge()
    getWaypointsFromAPI()
    // const userid = localStorage.getItem("userid")
    console.log("uuuuuuu", userid);
    
    getMarkers()
    // if (userid) generateWaypoints()

  }, [])

  const getWaypointsFromAPI = async () => {
    const { productId } = params;
    let ids = productId.split(",")
    let mm_id = ids[0]
    let data = await getWaypointsById(mm_id)

    if (!Array.isArray(data)) data = []
    setWaypointsData(data)
  }

  const getUser = async () => {
    console.log("ccc getuser called");
    
    const { productId } = params;
    let ids = productId.split(",")
    let mm_id = ids[0]
    let userData = await getUsersByProductId(mm_id);
    if (!Array.isArray(userData)) userData = []
    setUsers(userData)
  }

  const getChallenge = async () => {
    const { productId } = params;
    let ids = productId.split(",")
    let mm_id = ids[0]
    let vc_id = ids[1]
    const data = await getChallengeById(vc_id ? vc_id : mm_id)

    if (data.length)
      setChallenge({ ...data[0], pathCoordinates: JSON.parse(data[0].pathCoordinates || null) })
  }

  useEffect(() => {
    if (polyline) {
      console.log("uuuuuuuuuuuuuuuuuuuuuu", userid);
      
      // const userid = localStorage.getItem("userid")
      getMarkers();
      generateMilestones();
      if(userid && waypointsData) generateWaypoints()
    }

    return () => { };
  }, [polyline, users, waypointsData]);

  const markerClickedHandler = (user, position) => {
    setInfoWindow(<InfoWindow onCloseClick={() => setInfoWindow(null)} position={position}>
      <div>
        {userid && userid == user.userid && <><span style={{ color: 'black', fontWeight: 'bold' }}>Id: <span style={{ color: 'blue', fontWeight: 'bold' }}>{user.userid}</span></span>
        <br /></> }
        <span style={{ color: 'black', fontWeight: 'bold' }}>Distance: <span style={{ color: 'blue', fontWeight: 'bold' }}>{user.distanceKmv2} km</span></span>
      </div>
    </InfoWindow>)
  }

  function generateMilestones() {
    if (challenge?.milestones?.length) {
      const milestoneData = challenge.milestones.map((milestone) => {
        const position = getPositionByPercentage(
          milestone_percentage_config[milestone.oncomplete]
        );
        return (
          <Marker
            position={position}
            icon={icon_milestone}
            key={new Date().toISOString()}
          />
        );
      });
      setMilestones(milestoneData);
    }
  }

  function generateWaypoints() {
    
    // if (challenge?.milestones?.length) {
      let logo = lockedwaypointLogo
      let user = null
      let userPercentage: any = 0
      let completedWaypoints: any = ""
      let flag = false
      let waypointsToMap: Array<WayPoint> = JSON.parse(JSON.stringify(waypointsData))
      if(userid) {
        console.log("users", users);
        
        user = users.find(user => user.userid == userid)
        if(user) {
        userPercentage = user?.percentage
        console.log("userPercentage", user, userid);
        
        completedWaypoints = user?.completedWaypoints
        console.log("ccc1", completedWaypoints);
        
        if(typeof completedWaypoints == "string") completedWaypoints = completedWaypoints.split(",").filter(e => e && e)
        console.log("ccc2", waypointsToMap, completedWaypoints);

        const openable = completedWaypoints?.length ? waypointsToMap.map(e => e?._id).filter((element) => !completedWaypoints.includes(element))[0] : waypointsToMap[0]?._id;

        console.log(completedWaypoints?.length, "ccccccc===>", waypointsToMap.map(e => e._id).filter((element) => completedWaypoints.includes(element)));
        

        waypointsToMap = waypointsToMap.map(item => {
          const { _id } = item;
          const is_unlocked = completedWaypoints.find(c_id => c_id == _id)
          
          console.log("ccc3", {userPercentage}, {ip: item.percentage}, {openable}, {_id});
          
          const is_openable = (userPercentage >= item.percentage) && (openable == _id)
          console.log("ccc4", is_openable)
          return {
            ...item,
            is_unlocked,
            is_openable
          }
        })
      

      
      const waypoint_ui = waypointsToMap.map((waypoint) => {
        const position = getPositionByPercentage(waypoint.percentage);
        
        return (
          <Marker
            onClick={() => handleOpenModal(waypoint, position, waypoint.is_openable, waypoint.is_unlocked )}
            position={position}
            icon={getWaypointLogo(waypoint.is_openable, waypoint.is_unlocked )}
            key={new Date().toISOString()}
            animation={google.maps.Animation.DROP}
          />
        );
      });
      setWaypoints(waypoint_ui);
    }
  }
  }


  function getMarkers() {
    
    const userMarkers = users.map(user => {

      const position = getPositionByPercentage(user.percentage);
      if (user.userid == userid)
        return <Marker onClick={() => markerClickedHandler(user, position)} position={position} animation={google.maps.Animation.BOUNCE} icon={userIcon} key={user.userid} />
      else return <Marker onClick={() => markerClickedHandler(user, position)} position={position} icon={icon} key={user.userid} />
    })
    setMarkers(userMarkers)
    if (users && users.length && userid) {
        const user = users.find((user) => user.userid == userid)
        if (user) {
          const position = getPositionByPercentage(user.percentage);
          console.log("0th position", position);
                    
          setCenter(position)
      }
    }
  }



  // const getPositionByPercentage = (percentage: number) => {
  //   const { pathCoordinates } = challenge;
  //   const position = ((pathCoordinates.length - 1) * percentage) / 100;
  //   return pathCoordinates[Math.floor(position)];
  // };
  const getPositionByPercentage = (percentage: number) => {
    // const { pathCoordinates } = challenge;
    // const position = ((pathCoordinates.length - 1) * percentage) / 100;

    // let x = google.maps.geometry.spherical.interpolate(pathCoordinates[0], pathCoordinates[pathCoordinates.length - 1], percentage)
    // let projection = google?.maps?.getProjection();


    if (!percentage) percentage = 0.01;
    if (percentage > 100) percentage = 100;

    google.maps.LatLng.prototype.distanceFrom = function (newLatLng) {
      var EarthRadiusMeters = 6378137.0; // meters
      var lat1 = this.lat();
      var lon1 = this.lng();
      var lat2 = newLatLng.lat();
      var lon2 = newLatLng.lng();
      var dLat = (lat2 - lat1) * Math.PI / 180;
      var dLon = (lon2 - lon1) * Math.PI / 180;
      var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      var d = EarthRadiusMeters * c;
      return d;
    }

    google.maps.Polyline.prototype.Distance = function () {
      var dist = 0;
      for (var i = 1; i < this.getPath().getLength(); i++) {
        dist += this.getPath().getAt(i).distanceFrom(this.getPath().getAt(i - 1));
      }
      return dist;
    }

    let distance = Math.floor((polyline.Distance() * percentage) / 100);

    if (!distance) return undefined;

    function GetPointAtDistance(metres) {
      // some awkward special cases
      if (metres == 0) return polyline.getPath().getAt(0);
      if (metres < 0) return null;
      if (polyline.getPath().getLength() < 2) return null;
      var dist = 0;
      var olddist = 0;
      for (var i = 1; (i < polyline.getPath().getLength() && dist < metres); i++) {
        olddist = dist;
        dist += polyline.getPath().getAt(i).distanceFrom(polyline.getPath().getAt(i - 1));
      }
      if (dist < metres) {
        return null;
      }
      var p1 = polyline.getPath().getAt(i - 2);
      var p2 = polyline.getPath().getAt(i - 1);
      var m = (metres - olddist) / (dist - olddist);
      return new google.maps.LatLng(p1.lat() + (p2.lat() - p1.lat()) * m, p1.lng() + (p2.lng() - p1.lng()) * m);
    }
    console.log("poly", GetPointAtDistance(distance));
    let position = GetPointAtDistance(distance)
    return { lat: position.lat(), lng: position.lng() }
    // return pathCoordinates[Math.floor(position)];
  };

  const onLoad = (poly) => {
    setPolyline(poly);
    // var service = new google.maps.DirectionsService();

    // // console.log('polyline: ', poly.GetPointAtDistance(1000))
    // let path = poly.getPath();
    // // console.log("========>", path.getAt(0).lat(), path.getAt(1).lat());

    // // alert(latitude_longitude); ////Gets latitude and Longitude - Latitude first - Longitude second

    // if (path.getLength() === 0) {
    // } else {
    //   service.route(
    //     {
    //       origin: path.getAt(0),
    //       destination: path.getAt(path.getLength() - 1),
    //       travelMode: google.maps.DirectionsTravelMode.WALKING,
    //     },
    //     function (result, status) {
    //       let distance = 0;
    //       for (let i = 0; i < path.getLength() - 2; i++) {
    //         distance += google.maps.geometry.spherical.computeDistanceBetween(
    //           path.getAt(i),
    //           path.getAt(i + 1)
    //         );
    //       }
    //       let final = distance.toFixed(2);
    //       setTotalDistance(JSON.parse(final));
    //     }
    //   );
    // }
  };

  // const userLoginToMap = (userid) => {
  //   localStorage.setItem('userid', userid)
  //   getMarkers(userid)
  //   generateWaypoints()
  // }

  // const userLogOutFromMap = () => {
  //   localStorage.removeItem('userid')
  //   getMarkers()
  //   setWaypoints([])
  // }


  return isLoaded ? (
    <div className={!challenge ? "center" : ""}>
      {challenge ? <>
        <div className="navbar">
          <div className="nav">
            <img src={logo} style={{ height: 40 }} />
            <h3></h3>
          </div>

          <div className="heading">{challenge.name}, {challenge.miles}km</div>

        </div>

        {/* {challenge.pathCoordinates && users?.length ? !userid ? <><input onChange={(event) => setText(event.target.value)} style={{ margin: "12px 10px", padding: "8px", width: "180px" }} placeholder="Enter your Race Number" />
          <button onClick={() => userLoginToMap(text)} disabled={!text} style={{ padding: "8px" }}>Submit</button></> : <div style={{ margin: "8px 10px", display: "inline-block" }}><span style={{ color: "#c17846", fontWeight: "bolder" }}>Race number : {localStorage.getItem('userid')}</span> <button onClick={() => userLogOutFromMap()}>Reset</button></div> : <p></p>} */}
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={challenge.bounds && challenge.bounds != "null" ? 7.3 : 13}
          onLoad={onMapLoad}
          options={{ restriction: (challenge.bounds && challenge.bounds != "null") ? { latLngBounds: JSON.parse(challenge.bounds) } : null, minZoom: (challenge.bounds && challenge.bounds != "null") ? 7.3 : null }}

        // onUnmount={onUnmount}
        >
          {/* Child components, such as markers, info windows, etc. */}
          <>
            {challenge?.bounds && challenge?.mapOverlay && <GroundOverlay
              key={'url'}
              url={challenge.mapOverlay}
              bounds={JSON.parse(challenge.bounds)}
            />}
            {/* <Marker position={options.paths[0]} icon={icon} />
        <Marker position={options.paths[options.paths.length - 1]} /> */}

            {google && users && challenge.pathCoordinates && (
              <Polyline onLoad={onLoad} path={challenge.pathCoordinates} options={options} />
            )}
            {users?.length && markers}
            {/* {milestones} */}
            {infoWindow}
            {waypoints}
            {/* {totalDistance && } */}
          </>
        </GoogleMap>
      </> : (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
          <div className="loader"></div>
          <p>Please wait...</p>
        </div>
      )}
      <Modal isOpen={isModalOpen} onClose={handleCloseModal} getUser = {getUser} waypoint={waypoint}/>
    </div>
  ) : (
    <>Loading the Map!</>
  );
}

export default React.memo(MyComponent);
