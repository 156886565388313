import React, { useState } from 'react';
import './style.css'
import YouTube from 'react-youtube';
import Stepper from "awesome-react-stepper";
import Swal from 'sweetalert2';
import { unlockWaypoint } from '../../globalService/api.service';
import { useParams } from 'react-router-dom';


const Modal = ({ isOpen, onClose, getUser, waypoint }) => {
  const [activeOption, setActiveOption] = useState(null)
  const params = useParams()
  const {userid} = params

  if (!isOpen) return null;

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};

const handleOptionSelect = (key) => {
  setActiveOption(key)
}

const onSubmitWaypoint = async () => {
  if(isOpen === "is_unlocked") {
    setActiveOption(null)
      onClose()
  }
  else if(waypoint.quiz) {
  const correctAnswerKey = waypoint.quiz.answer
  const correctAnswer = waypoint.quiz.options[correctAnswerKey]
  const yourAnswer = waypoint.quiz.options[activeOption]
  const message = activeOption ? (correctAnswerKey == activeOption ? "Congrats! Your answer is correct" : "Sorry wrong answer") : "Waypoint is not saved"
   const secondMsg = activeOption ? (correctAnswerKey == activeOption ? `Waypoint unlocked | Answer : ${yourAnswer} | Reward : ${waypoint.reward}` : `Waypoint unlocked | Your Answer : ${yourAnswer} | Correct Answer : ${correctAnswer} | Reward : ${waypoint.reward}`) : "Clear the question to save the waypoint"
  const status = correctAnswerKey == activeOption ? "success" : "error"
  const title = activeOption ? 'Do you want to submit the answer?' : 'No answer choosen. Do you wanna skip?'

  Swal.fire({
    title,
    showDenyButton: false,
    showCancelButton: true,
    confirmButtonText: 'Submit',
    denyButtonText: `Don't save`,
  }).then(async (result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
      let reward = 0
      if((status === "success") ) {
        reward = waypoint.reward ? waypoint.reward : reward
      }
      const { productId, _id } = waypoint
      try {
      let response: any = null
      if(activeOption && isOpen !== "is_unlocked") response = await unlockWaypoint(productId, userid, {completedWaypoints: _id, reward})
      if(response?.updatedStatus?.affectedRows) {
        getUser()
        Swal.fire(message, secondMsg, status)
      } else {
        Swal.fire("Something went wrong", "Try again later", "error")
      }
      setActiveOption(null)
      onClose()
      } catch (error) {
        console.log("err", error);
        Swal.fire("Something went wrong", "Try again later", "error")
       setActiveOption(null)
       onClose()
      }
      
      
      
    } 
  })
  } else {
    const { productId, _id } = waypoint
    await unlockWaypoint(productId, userid, {completedWaypoints: _id, reward: 0})
    getUser()
    setActiveOption(null)
    onClose()
    Swal.fire("Waypoint unlocked", "Go ahead", "success")
  }
  
  // if(correctAnswer == activeOption) {
  //   alert("correct answer")
  // } else {
  //   alert("wrong answer")
  // }
}
  
const generateContent = () => {
  let {video, image, text, quiz} = waypoint
     if(video) video = <YouTube id="yt" className='content-container' videoId={video} />
     if (image) image = <img width={310} className='content-container' src={image}/>
     if (text) text = <p className='content-container'>{text}</p>

  //quiz part start -----------------------------------
     if(quiz) {
      const {question, answer, options} = quiz
      if(question && answer && options) {
        quiz = 
        <div className='content-container'>
        <h3>{isOpen === "is_unlocked" ? "🎉 Waypoint is unlocked!" : "Now here is a question for you:"}</h3>
        <div className='quiz-card'>
        <h4 style={{color: "#c17846"}}>{question}</h4>
        {isOpen === "is_unlocked" ? <div style={{fontWeight: "bold"}}>{`Correct Answer : ${options[answer]}`}</div> : Object.keys(options).map((key) => 
        <div key={key} className={`quiz-option ${activeOption == key ? "active-quiz-option" : "inactive-quiz-option"}`} onClick={() => handleOptionSelect(key)}>
          {options[key]}
        </div>
        )}
        </div>
        
      </div>
      }
   }
  //quiz part end -------------------------------
    
    const orderObject = {video, image, text, quiz}
    const order = waypoint?.order?.length ? waypoint.order : []
    return order.map(o => orderObject[o]).filter(o => o && o)

}
  return (
    <div className="modal ">
      <div className="modal-content" >
        

        <div className="cross-button close-button" onClick={onClose}>
          <span className="horizontal-line"></span>
          <span className="vertical-line"></span>
        </div>
        {/* <button className="close-button" onClick={onClose}>
          Close
        </button> */}
        <h4>{waypoint?.title}</h4>

<Stepper onSubmit={() => onSubmitWaypoint()} submitBtn={<button className='defaultBtn'>{isOpen === "is_unlocked" ? "Close" : "Submit"}</button>}>
  {waypoint ? generateContent() : <>loading...</>}
</Stepper>

        {/* {waypoint ? generateContent() : <>loading...</>} */}
        {/* Add your modal content here */}
      </div>
    </div>
  );
};

export default Modal;


