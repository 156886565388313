export const milestone_percentage_config = {
    0: 0,
    1: 100,
    2: 75,
    3: 50,
    4: 25,
    5: 20,
    6: 40,
    7: 60,
    8: 80,
    9: 10,
    10: 30,
    11: 70,
    12: 90,
    13: 5,
    14: 15,
    15: 8,
    16: 2,
    17: 85,
    18: 55,
    19: 65,
    20: 68,
    21: 22,
    22: 28,
    23: 36,
    24: 48,
    25: 61,
    26: 93,
    27: 98,
    28: 56,
    29: 1,
    30: 12,
    31: 44,
    32: 3,
    33: 4,
    34: 16,
    35: 17,
    36: 18,
    37: 19,
    38: 23,
    39: 24,
    40: 26,
    41: 27,
    42: 28,
    43: 29,
    44: 19,
    45: 32,
    46: 33,
    47: 34,
    48: 35,
    49: 37,
    50: 38,
    51: 71,
    52: 72,
    53: 73,
    54: 74,
    55: 76,
    56: 77,
    57: 78,
    58: 79,
    59: 81,
    60: 82,
    61: 83,
    62: 84,
    63: 86,
    64: 87,
    65: 41,
    66: 42,
    67: 43,
    68: 45,
    69: 46,
    70: 47,
    71: 49,
    72: 99
}
