import { ReactNode} from "react";
import Challanges from "../pages/Challenges/Challanges";
import ViewMap from "../pages/ViewMap/ViewMap";

export const routes: Array<{path: string, component: ReactNode}> = [

    {path: '/:userid', component: <Challanges/>},
    {path: '/map/:productId/:userid', component: <ViewMap/>},
    {path: '/', component: <Challanges/>},
    {path: '/map/:productId', component: <ViewMap/>}
]