import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react';
import './challenges-style.css'
import { assets_base_url, getChallengesForMap } from '../../globalService/api.service';
import _ from "lodash"

export default function () {
  const navigate = useNavigate();
  const [challenges, setChallenges] = useState([])
  const [challengesCopy, sethallengesCopy] = useState([])
  const [searchText, setSearchText] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  // const [userid, setUserid] = useState("")
  const [racenumber, setRecenumber] = useState("")
  const params = useParams()
  const {userid} = params



  const inputCallback: any = _.debounce(function (text) {
    const searchResult = challengesCopy.filter(challenge => challenge.name.match(new RegExp(text, "i")));
    setChallenges(searchResult)
    setSearchText(text)
  }, 500);

  useEffect(() => {
    // const id = localStorage.getItem('userid')
    // if(id) setUserid(id)
    setIsLoading(true)
    getChallenges()
    return () => {

    }
  }, [])


  const getChallenges = async () => {
    const data = await getChallengesForMap();
    setIsLoading(false)
    sethallengesCopy(data)
    setChallenges(data);
  }

  const onChangeHandler = (event: any) => {
    event.persist();
    inputCallback(event.target.value);

  }

  const onUserIdType = (event: any) => {
    const {value} = event.target;
    setRecenumber(value)
  }

  // const onUserIdSubmit = () => {
  //   localStorage.setItem('userid', racenumber)
  //   setUserid(racenumber)
  // }
 

  const getMapImage = (img) => {
    if(isJSONParsable(img)) {
      return `${assets_base_url}/${JSON.parse(img).image_intro}`
    } else {
      return img
    }
  }

  function isJSONParsable(str) {
    try {
      JSON.parse(str);
      return true;
    } catch (error) {
      return false;
    }
  }

  const inputEl = useRef(null);

  const clearSearch = () => {
    inputEl.current.value = "";
    setChallenges(challengesCopy);
  }

  

  // const resetUser = () => {
  //   localStorage.removeItem('userid')
  //   setUserid("")
  // }

  return (
    <div style={{ margin: 20 }}>
      <div >
      <div>
      <h3>Click on your challenge to open the map view and enter your race ID to find your position.</h3>
      <p>Only certain challenges have interactive maps.</p>
      </div>

      <div style={{display: "flex", justifyContent: "center", marginBottom: 30}}>
        {/* {!userid ? 
        <><input onChange={(event) => onUserIdType(event)} style={{width: 210}} placeholder='Enter your Race number here'/>
        <button disabled={!racenumber} onClick={() => onUserIdSubmit()} className='searchBtn'>Submit</button></> 
        : 
        <div style={{fontWeight: "bold", color: "#c17846"}}>
          Your Race number is : {userid}
          <button onClick={() => resetUser()} style={{margin: "0 8px"}}>Reset</button>
        </div>} */}
      </div>
      </div>
      
      
      <div className='searchBox'>
        <input ref={inputEl} className='searchInp' placeholder='search your challenge name' onChange={(event) => onChangeHandler(event)} />
        <button className='searchBtn' disabled={!searchText} onClick={() => clearSearch()}>✕ clear</button>
      </div>
      <div className='challenge-container'>

        {!isLoading ? <>
          {
            challenges.length ? challenges?.map(challenge => <div key={challenge.id} onClick={() => navigate(`/map/${challenge.id},${challenge._id || ""}/${userid || ""}`)} className='challenge-card'>
              <p>{challenge.name}</p>
              <p>Distance: {challenge.miles} km</p>
              <img className='challenge-img' src={getMapImage(challenge.images)} />
            </div>) : (<p>No data found</p>

            )

          }
        </> : <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <div className="loader"></div>
          <p>Please wait</p>
        </div>}
      </div>

    </div>
  )
}
