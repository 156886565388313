import axios from "axios";
import { User, Challenge, WayPoint } from "./api.model";

const base_url = 'https://virtual-challenge-maps.herokuapp.com';

export const assets_base_url = 'https://medalmad.com'

// const endPoints = {
//     usersByProduct: "usersByProduct"
// }

const getUrl = (endPoint: string):string => {
    return `${base_url}/api/v1/${endPoint}`
}

export const getChallengesForMap = async(): Promise<Challenge[] | undefined> => {
    try {
        const response = await axios.get(getUrl('challengesForMap'));
        // console.log(response.data);
        
        if(response) return response.data;
    } catch (error: any) {
        console.log(error);
        
        return error.message
    }
}

export const getUsersByProductId = async(productId: string): Promise<User[] | undefined> => {
    try {
        const response = await axios.get(`${getUrl('usersByProduct')}/${productId}`);
        if(response) return response.data;
    } catch (error: any) {
        return error.message
    }
}

export const getChallengeById = async(productId: string): Promise<Challenge[] | undefined> => {
    try {
        const response = await axios.get(`${getUrl('challengeById')}/${productId}`);
        if(response) return response.data;
    } catch (error: any) {
        return error.message
    }
}

export const getWaypointsById = async(productId: string): Promise<WayPoint[] | undefined> => {
    try {
        const response = await axios.get(`${getUrl('waypoints')}/${productId}`);
        if(response) return response.data;
    } catch (error: any) {
        return error.message
    }
}

export const unlockWaypoint = async(productId: string, userId: string, thingsToUpdate: {completedWaypoints: string, reward: number}): Promise<WayPoint[] | undefined> => {
    try {
        const response = await axios.put(`${getUrl(`waypoints/unlock/${productId}/${userId}`)}`, thingsToUpdate);
        if(response) return response.data;
    } catch (error: any) {
        return error.message
    }
}